import ApiConnector from "../apiConnector";
import { TeamStory } from "../entities/teamStory";

class TeamStoryDataFetcher {
    private readonly apiConnector: ApiConnector;

    constructor(baseURL: string){
        this.apiConnector = new ApiConnector(baseURL);
    }

    public async getTeamStory(templateId: string): Promise<TeamStory[]> {
        if (isGuid(templateId)) 
            return this.apiConnector.get(`/teamstory/template/${templateId}/records`);
        else
            return this.apiConnector.get(`/teamstory/target/${templateId}/records`);            
    }

    public async getTemplate(templateId: string): Promise<TeamStory> {
        return this.apiConnector.get(`/teamstory/${templateId}`);
    }

    public async updateTeamStory(newTeamStoryData: TeamStory) {
        return this.apiConnector.put('/teamstory', newTeamStoryData);
    }

    public async createTeamStory(templateId: string, newTeamStoryData: TeamStory) {
        return this.apiConnector.post(`/teamstory/${templateId}`, newTeamStoryData);
    }

    public async deleteTeamStory(teamStoryId: number) {
        return this.apiConnector.delete(`/teamstory/${teamStoryId}`);
    }
}

function isGuid(input: string): boolean {
    const guidPattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    return guidPattern.test(input);
}


export default TeamStoryDataFetcher;